import React from 'react';
import { OnboardingFormInfoCompany, companySchema } from './components/OnboardingFormInfoCompany';
import {
  OnboardingFormInfoContact,
  contactInfoSchema,
  contactInfoSchemaName
} from './components/OnboardingFormInfoContact';
import { OnboardingFormInfoGovExperience, govExperienceSchema } from './components/OnboardingFormInfoGovExperience';
import { OnboardingFormInfoCVs, contractVehicleSchema } from './components/OnboardingFormInfoCVs';
import { OnboardingFormInfoInvitations } from './components/OnboardingFormInfoInvitations';

export type OnboardingStepName =
  | 'start'
  | 'contactInfo'
  | 'governmentExperience'
  | 'contractVehicles'
  | 'invitations'
  | 'completed';

type OnboardingStepInfo = {
  schema?: unknown;
  title: string;
  number: number;
  prev?: OnboardingStepName;
  next: OnboardingStepName;
  component: JSX.Element;
};

type OnboardingFlow = Partial<Record<OnboardingStepName, OnboardingStepInfo>>;

export const AdminFlow: OnboardingFlow = {
  start: {
    schema: companySchema,
    title: 'Welcome to Govly! 🎉',
    number: 1,
    next: 'contactInfo',
    component: <OnboardingFormInfoCompany />
  },
  contactInfo: {
    schema: contactInfoSchema,
    title: 'Your Info',
    number: 2,
    prev: 'start',
    next: 'governmentExperience',
    component: <OnboardingFormInfoContact />
  },
  governmentExperience: {
    schema: govExperienceSchema,
    title: 'Government Experience',
    number: 3,
    prev: 'contactInfo',
    next: 'contractVehicles',
    component: <OnboardingFormInfoGovExperience />
  },
  contractVehicles: {
    schema: contractVehicleSchema,
    title: 'Contract Vehicles',
    number: 4,
    prev: 'governmentExperience',
    next: 'invitations',
    component: <OnboardingFormInfoCVs />
  },
  invitations: {
    title: 'Team Members',
    number: 5,
    prev: 'contractVehicles',
    next: 'completed',
    component: <OnboardingFormInfoInvitations />
  }
};

export const MemberFlow: OnboardingFlow = {
  start: {
    schema: contactInfoSchema,
    title: 'Welcome to Govly! 🎉',
    number: 1,
    next: 'invitations',
    component: <OnboardingFormInfoContact />
  },
  invitations: {
    title: 'Team Members',
    number: 2,
    prev: 'start',
    next: 'completed',
    component: <OnboardingFormInfoInvitations />
  }
};

export const InviteeFlow: OnboardingFlow = {
  start: {
    schema: contactInfoSchemaName,
    title: 'Welcome to Govly! 🎉',
    number: 1,
    next: 'contactInfo',
    component: <OnboardingFormInfoContact showNames />
  },
  contactInfo: {
    schema: contactInfoSchema,
    title: 'Your Info',
    number: 2,
    prev: 'start',
    next: 'invitations',
    component: <OnboardingFormInfoContact />
  },
  invitations: {
    title: 'Team Members',
    number: 3,
    prev: 'contactInfo',
    next: 'completed',
    component: <OnboardingFormInfoInvitations />
  }
};
