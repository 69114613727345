import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import orderBy from 'lodash-es/orderBy';

import { useGetOppQuery } from 'api/oppsApi';
import { useGetOppWorkspaceQuery } from 'api/oppWorkspacesApi';
import { Timeline } from 'app/molecules/Timeline/Timeline';
import { OppWorkspaceComment } from 'app/organisms/OppWorkspaceComment/OppWorkspaceComment';
import { match, Pattern } from 'ts-pattern';
import { WorkspaceAttachmentShow } from 'types/__generated__/GovlyApi';
import { formatEvent, groupMergeableActivities } from './OppWorkspaceTimelineUtils';

export const OppWorkspaceTimeline = ({
  oppId: oppIdProp,
  workspaceId: workspaceIdProp
}: {
  oppId?: string;
  workspaceId?: string;
}) => {
  const { oppId: oppIdParam, workspaceId: workspaceIdParam } = useParams();
  const oppId = oppIdProp ?? oppIdParam;
  const workspaceId = workspaceIdProp ?? workspaceIdParam;

  const {
    data: { activities: oppActivities = [], organizationOpp: { activities: organizationOppActivities = [] } = {} } = {}
  } = useGetOppQuery({ id: oppId ?? '' }, { skip: !oppId });

  const { data: { activities = [], comments = [], attachments = [] } = {}, isLoading: workspaceLoading } =
    useGetOppWorkspaceQuery({ id: workspaceId ?? '' }, { skip: !workspaceId });

  const attachmentsByComment = useMemo(() => {
    return attachments.reduce(
      (acc, attachment) => {
        if (!attachment.commentId) return acc;

        if (!acc[attachment.commentId]) {
          acc[attachment.commentId] = [];
        }
        acc[attachment.commentId].push(attachment);
        return acc;
      },
      {} as Record<string, WorkspaceAttachmentShow[]>
    );
  }, [attachments]);

  const timelineItems = useMemo(() => {
    const sorted = orderBy(
      [...activities, ...oppActivities, ...organizationOppActivities, ...comments],
      ['createdAt'],
      ['desc']
    );
    const grouped = groupMergeableActivities(sorted);

    return grouped
      .map(event => {
        return match(event)
          .with({ message: Pattern.string.includes('posted a new comment') }, () => undefined)
          .with({ __typename: 'CommentIndex' }, comment => (
            <OppWorkspaceComment
              key={comment.id}
              comment={comment}
              oppId={oppId ?? ''}
              attachments={attachmentsByComment[comment.id] ?? []}
            />
          ))
          .with({ __typename: 'ActivityExtended' }, event => formatEvent(event))
          .exhaustive();
      })
      .filter(event => event !== undefined);
  }, [activities, oppActivities, organizationOppActivities, comments, attachmentsByComment, oppId]);

  if (workspaceLoading) {
    return null;
  }

  return <Timeline events={timelineItems} />;
};
