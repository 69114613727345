import { CommentShow } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Comment'] });

type GetComment = {
  params: { id: string };
  result: CommentShow;
};

export const commentsApi = api.injectEndpoints({
  endpoints: build => ({
    getComment: build.query<GetComment['result'], GetComment['params']>({
      query: ({ id }) => ({ url: `/v2/comments/${id}` }),
      providesTags: result => [...(result ? [{ type: 'Comment' as const, id: result.id }] : [])]
    })
  })
});

export const { useGetCommentQuery } = commentsApi;
