import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['CurrentUser'] });

type AddOnboardingInfo = {
  params: { [key: string]: string | number | boolean | string[] };
  result: void;
};

export const onboardingApi = api.injectEndpoints({
  endpoints: build => ({
    addOnboardingInfo: build.mutation<AddOnboardingInfo['result'], AddOnboardingInfo['params']>({
      query: body => ({
        url: `/v2/onboarding`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: () => ['CurrentUser']
    })
  })
});
export const { useAddOnboardingInfoMutation } = onboardingApi;
