import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Formik, Form, FormikErrors } from 'formik';
import { Button, Callout } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';
import { Loading } from 'app/atoms/Loading/Loading';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { CenterPageHighlight } from 'app/molecules/CenterPageHighlight/CenterPageHighlight';
import { useAddOnboardingInfoMutation } from 'api/onboardingApi';
import { errorToast } from 'app/lib/toaster';
import { useGetCurrentUserQuery } from 'api/currentUserApi';

import { useEventTracking } from 'app/hooks/useEventTracking';

import { AdminFlow, MemberFlow, InviteeFlow, OnboardingStepName } from './steps';

type InitialValues = {
  firstName: string;
  lastName: string;
  organizationName: string;
  organizationWebsite: string;
  organizationType: string[];
  organizationSize: string;
  jobTitle: string;
  phoneNumber: string;
  team: string;
  teamSize: string;
  governmentExperience: string;
  contractVehicles: string[];
  interestedInCustomContractVehicle: boolean;
  invitations: string[];
  onboardingStep: OnboardingStepName;
};

export const OnboardingForm = () => {
  const { trackEvent } = useEventTracking();
  const [step, setStep] = useState<OnboardingStepName>('start');
  const [steps, setSteps] = useState(AdminFlow);
  const [addOnboardingInfo, { isLoading: isSubmitting }] = useAddOnboardingInfoMutation();
  const { data: currentUser, isLoading } = useGetCurrentUserQuery();
  const { onboardingInfo, organization, role } = currentUser || {};
  const userHasName = currentUser?.name != null;
  const onboardingStep = currentUser?.onboardingStep as OnboardingStepName;

  const initialValues = {
    firstName: onboardingInfo?.firstName ?? '',
    lastName: onboardingInfo?.lastName ?? '',
    organizationName: onboardingInfo?.organizationName || organization?.name || '',
    organizationWebsite:
      onboardingInfo?.organizationWebsite || organization?.primaryDomain || organization?.approvedDomains?.[0] || '',
    organizationType: onboardingInfo?.organizationType || [],
    organizationSize: onboardingInfo?.organizationSize ?? '',
    jobTitle: onboardingInfo?.jobTitle ?? '',
    phoneNumber: onboardingInfo?.phoneNumber ?? '',
    team: onboardingInfo?.team ?? '',
    teamSize: onboardingInfo?.teamSize ?? '',
    governmentExperience: onboardingInfo?.governmentExperience || '',
    contractVehicles: onboardingInfo?.contractVehicles || [],
    interestedInCustomContractVehicle: onboardingInfo?.interestedInCustomContractVehicle || false,
    invitations: onboardingInfo?.invitations || [],
    onboardingStep: onboardingInfo?.onboardingStep || 'start'
  } as InitialValues;

  useEffect(() => {
    if (onboardingStep) setStep(onboardingStep);

    if (role === 'member') setSteps(MemberFlow);
    if (!userHasName) setSteps(InviteeFlow);
  }, [isLoading, role, userHasName, onboardingStep]);

  const currentStepInfo = steps[step];

  const handleBackClick = ({ setErrors }: { setErrors: (errors: FormikErrors<InitialValues>) => void }) => {
    const currentStepInfo = steps[step];
    if (!currentStepInfo) return;

    const prevStep = currentStepInfo.prev;

    if (prevStep) {
      setErrors({});
      setStep(prevStep);
    }
  };

  const handleSubmit = async (values: InitialValues) => {
    const currentStepInfo = steps[step];
    if (!currentStepInfo) return;

    const nextStep = currentStepInfo.next;

    try {
      trackEvent({
        object: 'onboarding_step',
        action: 'completed',
        properties: {
          step,
          nextStep,
          stepNumber: currentStepInfo.number,
          stepCount: Object.keys(steps).length
        }
      });
      await addOnboardingInfo({ ...values, onboardingStep: nextStep });
      setStep(nextStep);
    } catch (e) {
      errorToast(e);
    }
  };

  if (isLoading) {
    return (
      <CenterPageHighlight showLogo>
        <Loading />
      </CenterPageHighlight>
    );
  }

  const progress = currentStepInfo ? (currentStepInfo.number / Object.keys(steps).length) * 100 : 0;

  return (
    <CenterPageHighlight showLogo>
      <Formik
        enableReinitialize
        validationSchema={steps[step]?.schema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isValid, setErrors }) => {
          if (step === 'completed') return <Navigate to="/opportunities" replace={true} />;

          return (
            <Form>
              <div className="relative top-1.5 h-1.5 rounded-t bg-gray-200">
                <div
                  className={cn('h-1.5 overflow-hidden rounded-tl bg-blue-500', {
                    'rounded-tr': progress === 100
                  })}
                  style={{ width: `${progress}%` }}
                />
              </div>
              <Card title={currentStepInfo?.title}>
                <CardBody>
                  {currentStepInfo?.number === 1 && (
                    <Callout intent="primary" title="We just have a few questions before we get started" />
                  )}

                  {currentStepInfo?.component}
                </CardBody>

                <CardFooter>
                  <Button type="submit" large intent="primary" loading={isSubmitting} disabled={isValid === false}>
                    {step === 'invitations' ? 'Submit' : 'Next'}
                  </Button>

                  {currentStepInfo?.prev && (
                    <Button large intent="primary" onClick={() => handleBackClick({ setErrors })}>
                      Back
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </CenterPageHighlight>
  );
};
