import React, { ReactElement, forwardRef } from 'react';
import { SectionProps, SectionCardProps, Section, SectionCard, Button } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';
import { CardHeading } from 'app/atoms/Typography/Typography';

export type CardProps = SectionProps;
export type CardSectionProps = SectionCardProps;

export const Card = forwardRef<HTMLDivElement, CardProps>(({ children, className, ...rest }, ref) => (
  <Section ref={ref} className={cn('lg:rounded rounded-none', className)} titleRenderer={CardHeading} {...rest}>
    {children}
  </Section>
));
Card.displayName = 'Card';

export const CardSection = forwardRef<HTMLDivElement, SectionCardProps>(({ ...props }, ref) => (
  <SectionCard ref={ref} {...props} />
));
CardSection.displayName = 'CardSection';

export const CardBody = forwardRef<HTMLDivElement, CardSectionProps>(({ className, ...rest }, ref) => (
  <CardSection ref={ref} className={cn('space-y-6', className)} {...rest} />
));
CardBody.displayName = 'CardBody';

type CardFooterProps = {
  children: ReactElement<typeof Button> | (ReactElement<typeof Button> | undefined)[];
  className?: string;
};

export const CardFooter = ({ children, className }: CardFooterProps) => (
  <CardSection className={cn('py-2 flex flex-row-reverse space-x-2 space-x-reverse', className)}>
    {children}
  </CardSection>
);
