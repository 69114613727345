import React from 'react';
import * as yup from 'yup';

import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { RadioGroupInput } from 'app/atoms/inputs/RadioGroupInput/RadioGroupInput';
import { teamSizes } from 'app/lib/businessClassifications';

const PHONE_REG_EXP = /\b(?:\+?1[-. ]?)?\(?([0-9]{3})\)?[-. ●]?([0-9]{3})[-. ]?([0-9|X]{4})( ?x\d{0,6})?\b/i;

export const contactInfoSchemaName = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required')
});

export const contactInfoSchema = yup.object({
  jobTitle: yup.string().required('Job Title is required'),
  phoneNumber: yup.string().required('Phone Number is required').matches(PHONE_REG_EXP, 'Phone number is not valid'),
  team: yup.string().required('Team is required'),
  teamSize: yup.string().required('Team Size is required')
});

type OnboardingFormInfoContactProps = {
  showNames?: boolean;
};

export const OnboardingFormInfoContact = ({ showNames = false }: OnboardingFormInfoContactProps) =>
  showNames ? (
    <div data-test="onboarding-form-info-contact-show-names" className="space-y-6">
      <TextInput name="firstName" label="First Name" />
      <TextInput name="lastName" label="Last Name" />
    </div>
  ) : (
    <div data-test="onboarding-form-info-contact" className="space-y-6">
      <TextInput name="jobTitle" label="Job Title" />
      <TextInput name="phoneNumber" label="Phone Number" />
      <TextInput name="team" label="Team" />
      <RadioGroupInput
        inline
        label="Team Size"
        name="teamSize"
        options={teamSizes.map(value => ({ value, label: value }))}
      />
    </div>
  );
