import React from 'react';
import { Button, Checkbox, EntityTitle, Popover, RadioGroup, SegmentedControl, Switch, Tag } from '@blueprintjs/core';
import { DateRangeInput3 } from '@blueprintjs/datetime2';
import { FieldArray, useFormikContext } from 'formik';
import enUS from 'date-fns/locale/en-US';
import startCase from 'lodash-es/startCase';

import { DATE_RANGE_OPTIONS, defaultState, OppSearchState } from 'app/hooks/search/useOppSearchCache';
import { useOppTypeaheadQuery } from 'api/oppsApi';
import { useGetNaicsCodesQuery } from 'api/classificationCodesApi';
import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import { InputFilterCardListItem } from 'app/molecules/InputFilterCardListItem/InputFilterCardListItem';
import { useGetFormikFilterCardListItemProps } from 'app/molecules/InputFilterCardListItem/useFormikFilterCardListItem';
import { match } from 'ts-pattern';
import { nearFutureDateShortcuts, farFutureDateShortcuts } from 'app/lib/dates';
import { FormikToBp } from 'app/lib/formikToBp';
import { CardList } from 'app/molecules/CardList/CardList';
import { QueryInputMultiSelect } from 'app/molecules/QueryInputMultiSelect/QueryInputMultiSelect';
import { useContractVehicleMultiSelect } from 'app/molecules/QueryInputMultiSelect/useContractVehicleMultiSelect';
import { LabelTag } from 'app/organisms/LabelTag/LabelTag';
import { useGetOrganizationLabelsQuery } from 'api/organizationLabelsApi';
import {
  ClassificationCodeTypeahead,
  GovernmentEntityTypeaheadOpps,
  TypeaheadItem
} from 'types/__generated__/GovlyApi';
import { useGetSetAsidesQuery } from 'api/setAsidesApi';
import { NavLink } from 'react-router-dom';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import { FormikCompositeMultiSelect } from 'app/molecules/InputCompositeFilter/FormikCompositeMultiSelect';
import { FormikCompositeQueryMultiSelect } from 'app/molecules/InputCompositeFilter/FormikCompositeQueryMultiSelect';
import { FormikCompositeUserMultiSelect } from 'app/molecules/InputCompositeFilter/FormikCompositeUserMultiSelect';
import { getFiltersCount } from 'app/hooks/useGetFormikFilterCountByField';
import { addYears } from 'date-fns';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { supportChatMessage } from 'app/lib/support-chat';
import { useActivateSLED } from 'app/organisms/SearchableFeeds/SearchableFeedsListSLED/useActivateSLED';
import { InputMultiSelectHook } from 'app/molecules/InputMultiSelect/utils';
import { InfoTooltip } from 'app/molecules/InfoTooltip/InfoTooltip';
import { DeepKeys } from '@tanstack/react-table';
import { makeSafeFormikFields } from 'app/lib/formik';
import { prependSelectAll } from 'app/molecules/UserMultiSelect/FormikUserMultiSelectUtils';
import { useGovernmentEntityTypeaheadQuery } from 'api/governmentEntitiesApi';
import { OppSearchRecordType } from './OppSearchRecordType';

const DEFAULT_DATE_RANGE = {
  range: defaultState.filters.dateRange,
  param: defaultState.filters.dateRangeParam
};

type Props = {
  wrapperClassName?: string;
  className?: string;
};

const { safeField, safeTuples, safeFields } = makeSafeFormikFields<OppSearchState>();

export const OppSearchFilters = ({ className, wrapperClassName }: Props) => {
  const { values, setValues, submitForm } = useFormikContext<OppSearchState>();
  const getFilterCardProps = useGetFormikFilterCardListItemProps<OppSearchState>();
  const filtersCount = getFiltersCount(values, v => v.filters);

  const { searchType, recordType } = values.filters;
  const isFed = searchType === 'fed';
  const isSled = searchType === 'sled';

  const isSolicitation = recordType.includes('Solicitation');
  const isPrediction = recordType.includes('Prediction');
  const isForecast = recordType.includes('Forecast');
  const isEvent = recordType.includes('Event');
  const isFutureRecordType = isPrediction || isForecast || isEvent;

  const { data: organization, isLoading: organizationLoading } = useGetCurrentOrganizationQuery();
  const { handleChange, isUpdating } = useActivateSLED();
  const hasSled = organization?.subscriptionHasSLED;
  const sledActivated = organization?.subscriptionHasSLEDActivated;
  const sledEnabled = sledActivated && hasSled;

  const sledTooltip = (
    <div className="prose prose-sm p-4">
      {!hasSled ? (
        <span className="block text-xs text-gray-500">
          SLED is now on Govly!{' '}
          <LinkTag
            tag="button"
            onClick={() => supportChatMessage('Hi, I would like to see SLED opportunities in Govly.')}
          >
            Request Access
          </LinkTag>
        </span>
      ) : !sledActivated ? (
        <span className="text-xs text-gray-500 flex items-center gap-1">
          <span>Your SLED feed is not activated.</span>
          <Button small intent="primary" outlined onClick={() => handleChange(organization?.id)} loading={isUpdating}>
            Activate
          </Button>
        </span>
      ) : undefined}
    </div>
  );

  const getField = useGetFormikField<OppSearchState>();

  // Helps filter collapse states reset correctly
  const rerenderKey = [values.meta.savedSearchId, values.meta.searchState].join(':');

  const existingDateRangeParams = new Set([
    values.filters.dateRangeParam,
    ...values.filters.secondaryDateRanges.map(x => x.param)
  ]);

  return (
    <CardList
      key={rerenderKey}
      title="Filter"
      wrapperClassName={wrapperClassName}
      className={className}
      rightElement={
        filtersCount > 0 ? (
          <Tag
            onRemove={e => {
              e.preventDefault();
              const { filters } = defaultState;
              setValues({ ...values, filters });
              submitForm();
            }}
          >
            {filtersCount}
          </Tag>
        ) : undefined
      }
    >
      <InputFilterCardListItem title="Search type" defaultIsOpen>
        <Popover
          content={sledTooltip}
          disabled={sledEnabled || organizationLoading}
          className="w-full"
          interactionKind="hover"
          placement="top"
        >
          <SegmentedControl
            {...FormikToBp.toSegmentedControl(getField('filters.searchType'))}
            fill
            small
            intent="primary"
            onValueChange={value => {
              setValues({
                ...values,
                filters: {
                  ...defaultState.filters,
                  searchType: value as OppSearchState['filters']['searchType']
                }
              });
              submitForm();
            }}
            options={[
              { value: 'fed', label: 'Federal' },
              { value: 'sled', label: 'SLED', disabled: !sledEnabled }
            ]}
          />
        </Popover>
      </InputFilterCardListItem>

      {isFed && <OppSearchRecordType />}

      <InputFilterCardListItem
        title="Date Range"
        defaultIsOpen
        {...getFilterCardProps({
          names: ['filters.dateRange', 'filters.dateRangeParam', 'filters.secondaryDateRanges'],
          submitOnChange: true
        })}
      >
        <div>
          <div className="space-y-4">
            <FieldArray name={safeField('filters.secondaryDateRanges')}>
              {helpers => (
                <>
                  {[
                    { range: values.filters.dateRange, param: values.filters.dateRangeParam },
                    ...values.filters.secondaryDateRanges
                  ].map((dateRange, index, all) => {
                    const isPrimary = index === 0;
                    const isSecondary = index > 0;
                    const secondaryIndex = index - 1;

                    const options = DATE_RANGE_OPTIONS.filter(({ value }) => {
                      const isNotLast = index + 1 < all.length;
                      if (isNotLast) {
                        const valueMatches = value === dateRange.param;
                        return valueMatches;
                      }

                      const notFoundInPrecedingRanges = !all.slice(0, index).find(x => x.param === value);
                      return notFoundInPrecedingRanges;
                    });

                    return (
                      <div key={index} className="relative" role="group" aria-label="date range group">
                        <RadioGroup
                          inline
                          options={options}
                          {...FormikToBp.toRadioGroup(
                            getField(
                              isPrimary
                                ? 'filters.dateRangeParam'
                                : (`filters.secondaryDateRanges.${secondaryIndex}.param` as DeepKeys<OppSearchState>)
                            )
                          )}
                        />

                        <DateRangeInput3
                          {...FormikToBp.toDateRange(
                            getField(
                              isPrimary
                                ? 'filters.dateRange'
                                : (`filters.secondaryDateRanges.${secondaryIndex}.range` as DeepKeys<OppSearchState>)
                            )
                          )}
                          locale={enUS}
                          allowSingleDayRange
                          fill
                          {...(isForecast || isPrediction ? { maxDate: addYears(new Date(), 10) } : {})}
                          shortcuts={match({
                            isFutureRecordType,
                            dateRangeParam: dateRange.param
                          })
                            .with(
                              { isFutureRecordType: false, dateRangeParam: 'respond_by' },
                              () => nearFutureDateShortcuts
                            )
                            .with(
                              { isFutureRecordType: true, dateRangeParam: 'respond_by' },
                              () => farFutureDateShortcuts
                            )
                            .otherwise(() => true)}
                        />

                        {isSecondary && (
                          <Button
                            className="absolute -top-1 -right-1"
                            aria-label={`Remove ${dateRange.param} date range`}
                            small
                            intent="danger"
                            minimal
                            icon="remove"
                            onClick={() => helpers.remove(secondaryIndex)}
                          />
                        )}
                      </div>
                    );
                  })}

                  <Button
                    fill
                    disabled={values.filters.secondaryDateRanges.length === 2}
                    outlined
                    small
                    icon="add"
                    aria-label="Add date range"
                    onClick={() => {
                      const param = DATE_RANGE_OPTIONS.find(x => !existingDateRangeParams.has(x.value))?.value;
                      helpers.push({ param, range: DEFAULT_DATE_RANGE.range });
                    }}
                  />
                </>
              )}
            </FieldArray>
          </div>
        </div>
      </InputFilterCardListItem>

      <FormikCompositeMultiSelect
        namesAndLabels={safeTuples(['filters.searchFields.exclude', 'Exclude'], ['filters.searchFields.only', 'Only'])}
        title="Search Fields"
        getItemTextLabel={item => item.label}
        getItemValue={item => item.value}
        useTypeaheadProps={{ options: { keys: ['label'] } }}
        items={[
          { label: 'Title', value: 'title' },
          { label: 'Details', value: 'details' },
          { label: 'Attachments', value: 'attachments' },
          { label: 'AI Summary', value: 'ai_summary' },
          { label: 'AI Title', value: 'ai_title' }
        ]}
      />

      {isSolicitation && (
        <InputFilterCardListItem
          title="Status"
          {...getFilterCardProps({ names: ['filters.status'], submitOnChange: true })}
        >
          {[
            { label: 'Open', value: 'open' },
            { label: 'Expired', value: 'expired' },
            { label: 'Canceled', value: 'cancelled' },
            { label: 'Awarded', value: 'awarded' }
          ].map(({ label, value }) => (
            <Checkbox
              key={value}
              inline
              label={label}
              {...FormikToBp.toCheckbox({ ...getField('filters.status'), value })}
            />
          ))}
        </InputFilterCardListItem>
      )}

      {isSled && (
        <InputFilterCardListItem
          title="Jurisdiction"
          {...getFilterCardProps({ names: ['filters.jurisdictionRegions'], submitOnChange: true })}
        >
          <QueryInputMultiSelect
            useHook={useOppTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
            hookArgs={[{ typeahead: 'jurisdiction_region', searchType, filterValues: ['Unknown', 'US'] }]}
            useTypeaheadProps={{ options: { keys: ['label', 'value', 'state'] } }}
            getItemValue={item => item.value}
            getItemsBasedProps={items =>
              FormikToBp.toMultiSelect({
                ...getField('filters.jurisdictionRegions'),
                items,
                getItemValue: item => item.value
              })
            }
            getItemTextLabel={item => item.label}
          />
        </InputFilterCardListItem>
      )}

      {isFed && isPrediction && (
        <FormikCompositeQueryMultiSelect
          namesAndLabels={safeTuples(
            ['filters.predictedContractVehicleNames', 'Include'],
            ['filters.predictedContractVehicleNamesNone', 'Exclude']
          )}
          title="Predicted Contract Vehicle"
          useHook={useOppTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
          hookArgs={[
            {
              typeahead: 'predicted_contract_vehicle',
              filterValues: ['Unknown'],
              searchType,
              recordType: ['Prediction']
            }
          ]}
          getItemTextLabel={item => item.label}
          getItemValue={item => item.value}
          useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        />
      )}

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(['filters.feeds', 'Include'], ['filters.feedsNone', 'Exclude'])}
        title="Feed"
        useHook={useOppTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
        hookArgs={[
          {
            typeahead: 'feed_name',
            filterValues: ['Unknown'],
            searchType,
            jurisdictionRegions: getField('filters.jurisdictionRegions').field.value,
            recordType: getField('filters.recordType').field.value,
            recordTypeNone: getField('filters.recordTypeNone').field.value
          }
        ]}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemValue={item => item.value}
        getItemTextLabel={item => startCase(item.label)}
      />

      {!isSled && (
        <FormikCompositeQueryMultiSelect
          title="Buyer"
          namesAndLabels={safeTuples(['filters.buyerIds', 'Include'], ['filters.buyerIdsNone', 'Exclude'])}
          additionalNames={safeFields('filters.buyerMatchById', 'filters.includeSubBuyers')}
          useHook={useGovernmentEntityTypeaheadQuery as InputMultiSelectHook<GovernmentEntityTypeaheadOpps>}
          hookArgs={[{ view: 'opps' }]}
          getItemValue={item => item.id}
          getItemTextLabel={item => item.name ?? ''}
          useTypeaheadProps={{ options: { keys: ['name', 'aliases'], shouldSort: false } }}
          filterCardProps={{ collapseProps: { keepChildrenMounted: true } }}
          collapsibleChildren={
            <>
              <RadioGroup
                className="mt-2"
                {...FormikToBp.toRadioGroup(getField('filters.buyerMatchById'))}
                inline
                options={[
                  { label: 'Filter by exact buyer match', value: 'true' },
                  { label: 'Full text search buyer names and aliases', value: 'false' }
                ]}
              />

              <Switch {...FormikToBp.toSwitch(getField('filters.includeSubBuyers'))} label="Include sub-buyers?" />
            </>
          }
        />
      )}

      <FormikCompositeMultiSelect
        title="Workspace Stage"
        namesAndLabels={safeTuples(['filters.workspaceStages', 'Include'], ['filters.workspaceStagesNone', 'Exclude'])}
        items={[
          { label: 'Qualifying', value: 'qualifying' },
          { label: 'Intend to Bid', value: 'intend_to_bid' },
          { label: 'Quoted', value: 'quoted' },
          { label: 'Submitted', value: 'submitted' },
          { label: 'Awarded', value: 'awarded' },
          { label: 'No Bid', value: 'no_bid' },
          { label: 'Not Awarded', value: 'not_awarded' }
        ]}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemValue={item => item.value}
        getItemTextLabel={item => item.label}
      />

      <FormikCompositeUserMultiSelect
        title="Following"
        modifyItems={prependSelectAll}
        namesAndLabels={safeTuples(
          [
            'filters.following',
            'Include',
            <span key="include">
              Only show opportunities <b>any</b> of these people are following
            </span>
          ],
          [
            'filters.followingAll',
            'Include all',
            <span key="all">
              Only show opportunities <b>all</b> of these people are following
            </span>
          ],
          [
            'filters.excludeFollowing',
            'Exclude',
            <span key="exclude">
              <b>Do not</b> show opportunities that these people are following
            </span>
          ]
        ).map(toNameAndLabelWithTooltip)}
      />

      <FormikCompositeUserMultiSelect
        title="Not Interested"
        namesAndLabels={safeTuples(
          [
            'filters.excludeIgnored',
            'Exclude',
            <span key="exclude">
              <b>Do not</b> show opportunities that these people have marked &apos;Not Interested&apos;
            </span>
          ],
          [
            'filters.ignored',
            'Include',
            <span key="include">
              Only show opportunities <b>any</b> of these people have marked &apos;Not Interested&apos;
            </span>
          ],
          [
            'filters.ignoredAll',
            'Include all',
            <span key="all">
              Only show opportunities <b>all</b> of these people have marked &apos;Not Interested&apos;
            </span>
          ]
        ).map(toNameAndLabelWithTooltip)}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(
          ['filters.labels', 'Include'],
          ['filters.labelsAll', 'Include all'],
          ['filters.labelsNone', 'Exclude']
        )}
        title="Labels"
        useHook={useGetOrganizationLabelsQuery}
        useTypeaheadProps={{ options: { keys: ['name', 'description'] } }}
        getItemTextLabel={item => item.name ?? ''}
        getItemLabel={label => <LabelTag {...label} />}
        getItemValue={item => item.id}
        getInputProps={({ index }) =>
          index === 0
            ? {
                formGroupProps: {
                  helperText: <NavLink to={'/settings/labels'}>Manage labels</NavLink>
                }
              }
            : {}
        }
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(
          ['filters.tags', 'Include'],
          ['filters.tagsAll', 'Include all'],
          ['filters.tagsNone', 'Exclude']
        )}
        title="Tags"
        useHook={useOppTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
        hookArgs={[{ typeahead: 'tags', filterValues: ['Unknown'], searchType }]}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemValue={item => item.value}
        getItemTextLabel={item => item.label}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(['filters.buyerDomains', 'Include'], ['filters.buyerDomainsNone', 'Exclude'])}
        title="Buyer Email Domain"
        useHook={useOppTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
        hookArgs={[{ typeahead: 'buyer_domain', filterValues: ['Unknown'], searchType }]}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemValue={item => item.value}
        getItemTextLabel={item => item.label}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(['filters.noticeType', 'Include'], ['filters.noticeTypeNone', 'Exclude'])}
        title="Notice Type"
        useHook={useOppTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
        hookArgs={[{ typeahead: 'notice_type', filterValues: ['Unknown'], searchType }]}
        getItemTextLabel={item => startCase(item.label)}
        getItemValue={item => item.value}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
      />

      {!isSled && (
        <>
          <FormikCompositeQueryMultiSelect
            namesAndLabels={safeTuples(['filters.setAside', 'Include'], ['filters.setAsideNone', 'Exclude'])}
            title="Set Aside"
            useHook={useGetSetAsidesQuery}
            getItemValue={item => item.code ?? 'missing code'}
            getItemTextLabel={item => item.name ?? item.code ?? ''}
            useTypeaheadProps={{ options: { keys: ['name', 'code'] } }}
          />

          <InputFilterCardListItem
            title="Recipient Count"
            {...getFilterCardProps({ names: ['filters.contractHolderCount'], submitOnChange: true })}
          >
            {[
              { label: '1-25', value: '1-25' },
              { label: '26-50', value: '26-50' },
              { label: '51-100', value: '51-100' },
              { label: 'More than 100', value: '>100' },
              { label: 'Unknown', value: 'unknown' }
            ].map(({ label, value }) => (
              <Checkbox
                key={value}
                inline
                label={label}
                {...FormikToBp.toCheckbox({ ...getField('filters.contractHolderCount'), value })}
              />
            ))}
          </InputFilterCardListItem>

          <FormikCompositeQueryMultiSelect
            namesAndLabels={safeTuples(
              ['filters.regions', 'Include'],
              ['filters.regionsAll', 'Include all'],
              ['filters.regionsNone', 'Exclude']
            )}
            title="Regions"
            useHook={useOppTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
            hookArgs={[{ typeahead: 'global_region', filterValues: ['Unknown'], searchType }]}
            getItemValue={item => item.value}
            getItemTextLabel={item => item.label}
            useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
          />

          <FormikCompositeQueryMultiSelect
            namesAndLabels={safeTuples(
              ['filters.naicsCodes', 'Include'],
              ['filters.naicsCodesAll', 'Include all'],
              ['filters.naicsCodesNone', 'Exclude']
            )}
            title="NAICS Codes"
            useHook={useGetNaicsCodesQuery as InputMultiSelectHook<ClassificationCodeTypeahead>}
            hookArgs={[{ view: 'typeahead', searchType }]}
            getItemValue={item => item.value}
            getItemTextLabel={item => item.label}
            useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
          />
        </>
      )}

      {isFed && (
        <FormikCompositeQueryMultiSelect
          namesAndLabels={safeTuples(
            ['filters.contractVehicleIds', 'Include'],
            ['filters.contractVehicleIdsNone', 'Exclude']
          )}
          title="Contract Vehicle"
          useHook={useContractVehicleMultiSelect}
          getItemTextLabel={item => item.contractName}
          getItemValue={item => item.id}
          useTypeaheadProps={{ options: { keys: ['contractName', 'label'] } }}
        />
      )}

      <FormikCompositeUserMultiSelect
        title="Viewed"
        namesAndLabels={safeTuples(
          ['filters.viewed', 'Include'],
          ['filters.viewedAll', 'Include all'],
          ['filters.excludeViewed', 'Exclude']
        )}
        modifyItems={prependSelectAll}
      />
    </CardList>
  );
};

const toNameAndLabelWithTooltip = ([name, label, tooltip]: readonly [string, string, React.ReactNode]): [
  string,
  React.ReactNode
] => [
  name,
  <EntityTitle
    key={name}
    title={label}
    tags={<InfoTooltip popoverProps={{ className: 'mt-0' }}>{tooltip}</InfoTooltip>}
  />
];
